<template>
	<v-card>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
			<v-spacer></v-spacer>
			<v-btn :color="primaryColor" dark style="margin-right: 10px" @click="getContainersList()">
				<i :title="$t('common.update')" class="fas fa-sync" />
			</v-btn>
		</v-card-title>
		<!-- DATATABLE -->
		<v-data-table :headers="headers" :items="list" :search="search">
			<template v-slot:item.Names="{ item }">
				<span>{{ item.Names[0].toString().substr(1) }}</span>
			</template>
			<template v-slot:item.Status="{ item }">
				<div v-if="item.StatusCode === 'up'" class="successContainer">
					{{ item.Status }}
				</div>
				<div v-if="item.StatusCode === 'created'" class="successContainer">
					{{ item.Status }}
				</div>
				<div v-if="item.StatusCode === 'exited'" class="errorContainer">
					{{ item.Status }}
				</div>
			</template>
			<template v-slot:item.actions="{ item }">
				<!-- Inspect agent -->
				<v-btn icon :color="primaryColor" :title="$t('containerList.inspectAgent')" @click="inspectAgent(item)">
					<i class="fas fa-edit" />
				</v-btn>
				<!-- Recover log -->
				<v-btn
					icon
					:color="primaryColor"
					:title="$t('containerList.viewLog')"
					@click="openAgentLogDialog(item.Names[0].toString().substr(1), item.Id)"
				>
					<i class="fa fa-eye" />
				</v-btn>
				<!-- Stop agent -->
				<v-btn
					icon
					:color="primaryColor"
					v-if="item.StatusCode == 'up'"
					:title="$t('containerList.stopAgent')"
					@click="stopAgent(item, 'stopped')"
				>
					<i class="fa fa-stop" />
				</v-btn>
				<!-- Start agent -->
				<v-btn icon v-else :color="primaryColor" :title="$t('containerList.startAgent')" @click="startAgent(item)">
					<i class="fa fa-play" />
				</v-btn>
				<!-- Delete agent -->
				<v-btn
					icon
					v-if="item.StatusCode !== 'up'"
					:color="primaryColor"
					:title="$t('containerList.deleteAgent')"
					@click="showDeleteAgentDialog(item.Id, item.ImageID, item.Names[0].toString().substr(1))"
				>
					<i class="fa fa-trash" />
				</v-btn>
				<!-- :disabled="disableButtons || item.StatusCode !== 'exited'" -->
			</template>
		</v-data-table>
		<!-- DIALOG TO DELETE AGENT -->
		<v-dialog persistent v-model="deleteContainerDialog" max-width="30%">
			<v-card>
				<v-card-title class="text-h5">
					{{ $t('containerList.deleteAgentDialogQuestion') }}
					<v-spacer></v-spacer>
					<v-btn icon :color="primaryColor" @click="handleDeleteDialog()">
						<i class="fa fa-window-close" />
					</v-btn>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="deleteAgent()">{{ $t('common.ok') }}</v-btn>
					<v-btn color="error" @click="hideDeleteAgentDialog()">{{ $t('common.cancel') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- DIALOG TO SHOW THE JSON METADATA -->
		<v-dialog persistent v-model="viewMetaDataDialog" max-width="55%">
			<v-card>
				<v-card-title class="text-h5">
					{{ $t('containerList.inspectingAgent') }}
					<v-spacer></v-spacer>
					<v-btn icon :color="primaryColor" @click="closeMetadataDialog()">
						<i class="fa fa-window-close" />
					</v-btn>
				</v-card-title>
				<div style="padding: 20px">
					<vue-json-editor
						v-model="metadataContent"
						:options="options_JsonEditor"
						:plus="false"
						height="500px"
						@error="onErrorForSubscriptions"
					/>
				</div>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="closeMetadataDialog()">{{ $t('common.close') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- MODAL FOR RECOVERING LOG BY CONTAINER -->
		<v-dialog persistent v-model="logDialog" max-width="30%">
			<v-card>
				<v-card-title class="text-h5">
					{{ $t('containerList.selectDateforViewLog') }}
					<v-spacer></v-spacer>
					<v-btn icon :color="primaryColor" @click="handleCloseViewLog()">
						<i class="fa fa-window-close" />
					</v-btn>
				</v-card-title>
				<div style="padding: 20px">
					<v-menu
						ref="menuDateLog"
						v-model="menuDateLog"
						:close-on-content-click="false"
						:return-value.sync="date"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<!-- label="Picker in menu" -->
							<v-text-field
								v-model="date"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
								:disabled="chk_fromBeginning"
							></v-text-field>
						</template>
						<v-date-picker v-model="date" no-title scrollable :locale="userLanguage">
							<v-spacer></v-spacer>
							<v-btn text :color="primaryColor" @click="menuDateLog = false"> Cancel </v-btn>
							<v-btn text :color="primaryColor" @click="$refs.menuDateLog.save(date)"> OK </v-btn>
						</v-date-picker>
					</v-menu>
				</div>
				<div style="padding: 20px">
					<v-checkbox v-model="chk_fromBeginning" :label="$t('containerList.sinceBeginning')"></v-checkbox>
				</div>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="recoverAgentLog()">{{ $t('common.download') }}</v-btn>
					<v-btn color="error" @click="handleCloseViewLog()">{{ $t('common.cancel') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- MODAL DIALOG TO SHOW WHILE AN ACTION IS IN PROGRESS -->
		<v-dialog v-model="loading" persistent max-width="35%">
			<v-card>
				<v-card-title class="text-h5 justify-center">
					{{ $t('common.actionInProgress') }}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-container fluid fill-height>
						<v-layout justify-center align-center>
							<v-progress-circular indeterminate :color="primaryColor"> </v-progress-circular>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import { getAgents, inspectAgentById, getLog, pauseAgent, playAgent, deleteAgentandInfoRegister } from '@/api/agents_API';
import { dockerObjectForFilter, getOrganization } from '@/api/common';
import VueJsonEditor from '@/components/ui/VueJsonEditor';

export default {
	components: {
		VueJsonEditor
	},
	data() {
		return {
			search: '',
			headers: [
				{
					text: 'Container name',
					align: 'start',
					value: 'Names'
				},
				{
					text: 'Imagen',
					value: 'Image'
				},
				{
					text: 'Status',
					value: 'Status'
				},
				{
					text: 'Actions',
					value: 'actions'
				}
			],
			list: [],
			logDialog: false,
			agentLogName: '',
			agentLogId: null,
			menuDateLog: false,
			date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
			chk_fromBeginning: true,
			agentToDelete: '',
			agentName: '',
			imageID: '',
			deleteContainerDialog: false,
			metadataContent: null,
			viewMetaDataDialog: false,
			options_JsonEditor: {
				mode: 'code',
				// enableSort: true,
				enableTransform: false,
				// eslint-disable-next-line no-unused-vars
				onEditable: function (node) {
					// now the control is not editable
					return false;
				}
			},
			loading: false,
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		userLanguage() {
			return this.userProperties.language;
		}
	},
	watch: {},
	mounted() {},
	created() {
		this.getContainersList();
	},
	methods: {
		// GET LIST (Containers)
		getContainersList() {
			var dockerFilter = dockerObjectForFilter(this.session);
			getAgents(dockerFilter).then((response) => {
				this.list = response.message;
				console.log('LISTA DE AGENTES');
				console.log(this.list);
				console.log(this.list[0].StatusCode);
			});
		},

		// Abro modal para consultar log por agente
		openAgentLogDialog(agentName, agentId) {
			this.logDialog = true;
			this.agentLogName = agentName;
			this.agentLogId = agentId;
		},

		// Close download log
		handleCloseViewLog() {
			this.logDialog = false;
		},

		// Descargar el log del agente
		async recoverAgentLog() {
			var d = new Date(this.date);
			var timeStamp = d.getTime();

			const since = !this.chk_fromBeginning && this.date !== '' ? (timeStamp / 1000).toFixed(0) : '';
			console.log('UNIX TIMESTAMP', since);
			await getLog(this.agentLogId, since).then(
				(response) => {
					this.downloadTxt(`${this.agentLogName}-log`, response.message);
					var titleDialog = this.$t('common.agent');
					titleDialog += ' ' + this.agentLogName + ' ';
					titleDialog += this.$t('containerList.logDownloaded');
					this.$puiNotify.success(titleDialog, this.$t('common.success'));
				},
				(error) => {
					this.$puiNotify.error(error, this.$t('common.error'));
				}
			);
			this.handleCloseViewLog();
		},

		// Download the log
		downloadTxt(filename, text) {
			var element = document.createElement('a');
			element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
			element.setAttribute('download', filename);

			element.style.display = 'none';
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
		},

		// Parar el contenedor (agente)
		async stopAgent(agent) {
			console.log(agent);
			var agentId = agent.Id;
			// show loading (modal dialog)
			this.loading = true;
			// eslint-disable-next-line no-unused-vars
			await pauseAgent(agentId)
				.then(
					(response) => {
						console.log('STOP AGENT');
						console.log(response.message);
						var titleDialog = this.$t('common.agent');
						titleDialog += ' ' + agent.Names[0].toString().substr(1) + ' ';
						titleDialog += this.$t('containerList.containerStopped');
						this.$puiNotify.success(titleDialog, this.$t('common.success'));
						this.getContainersList();
					},
					(error) => {
						this.$puiNotify.error(error, this.$t('common.error'));
					}
				)
				.finally(() => {
					this.loading = false;
				});
		},

		// Arrancar el contenedor
		async startAgent(agent) {
			console.log('START AGENT');
			console.log(agent);
			var agentId = agent.Id;
			// show loading (modal dialog)
			this.loading = true;
			await playAgent(agentId)
				.then(
					// eslint-disable-next-line no-unused-vars
					(response) => {
						var titleDialog = this.$t('common.agent');
						titleDialog += ' ' + agent.Names[0].toString().substr(1) + ' ';
						titleDialog += this.$t('containerList.containerStarted');
						this.$puiNotify.success(titleDialog, this.$t('common.success'));
						this.getContainersList();
					},
					(error) => {
						this.$puiNotify.error(error, this.$t('common.error'));
					}
				)
				.finally(() => {
					this.loading = false;
				});
		},

		// Borrar el contenedor
		showDeleteAgentDialog(id, imageID, agentName) {
			console.log('------------------');
			console.log(id);
			console.log(imageID);
			console.log(agentName);
			console.log('------------------');

			this.agentToDelete = id;
			this.agentName = agentName;
			this.imageID = imageID;
			this.deleteContainerDialog = true;
		},

		// Oculto el modal para borrar el contenedor
		hideDeleteAgentDialog() {
			this.agentToDelete = '';
			this.agentName = '';
			this.imageID = '';
			this.deleteContainerDialog = false;
		},

		// Close Delete container
		handleDeleteDialog() {
			this.deleteContainerDialog = false;
		},

		/* Borrar agente */
		async deleteAgent() {
			// console.log(this.agentName);
			var agentToRemove = this.agentName;
			// Recuperamos la organización con la que estoy trabajando
			var organization = getOrganization(this.session);
			// Preparamos el objeto a enviar. Pues tambien vamos a borrar el registro de la tabla Info.
			var removeObject = {
				container_name: agentToRemove,
				organizationName: organization,
				agentID: this.agentToDelete
			};
			console.log(removeObject);
			console.log(agentToRemove);

			await deleteAgentandInfoRegister(removeObject).then(
				async (response) => {
					console.log(response);
					this.hideDeleteAgentDialog();

					// Una vez borradas ambas entidades se vacia la property imageID
					this.imageID = '';
					this.agentToDelete = '';
					var titleDialog = this.$t('common.agent');
					titleDialog += ' ' + agentToRemove + ' ';
					titleDialog += this.$t('containerList.containerDeleted');
					// Notificación para indicar al usuario que se ha borrado el contenedor
					this.$puiNotify.success(titleDialog, this.$t('common.success'));
					this.getContainersList();
				},
				(error) => {
					console.log(error);
					this.$puiNotify.error(error, this.$t('common.error'));
					// Pese al error la property imageID debe vaciarse
					this.imageID = '';
					this.agentToDelete = '';
				}
			);
		},

		/* Inspeccionar agente */
		async inspectAgent(row) {
			console.log('Agente a inspeccionar');
			console.log(row);
			this.metadataContent = '';
			const _this = this;
			// eslint-disable-next-line no-unused-vars
			const metadataContent = await inspectAgentById(row.Id).then((response) => {
				_this.metadataContent = response.message;
				console.log('Inspect Agent');
				console.log(response.message);
			});

			this.viewMetaDataDialog = true;
		},

		closeMetadataDialog() {
			this.viewMetaDataDialog = false;
			this.metadataContent = '';
		},

		// Error for Json Editor (Subscriptions)
		onErrorForSubscriptions() {
			console.log('error');
		}
	}
};
</script>

<style lang="postcss" scoped>
.successContainer {
	color: green;
	font-weight: bold;
}

.errorContainer {
	color: red;
	font-weight: bold;
}
</style>
